import React from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import styled from 'styled-components';
import { HashLink } from 'react-router-hash-link';

import LogoImage from '../../Assets/Images/logo.png';

const NavbarStyled = styled(Navbar)`
  background-color: white;
`;

const NavStyled = styled(Nav)``;

const Logo = styled.img`
  width: 11%;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 37%;
  }
`;

const NavLink = styled(HashLink)`
  font-size: 1.4rem;
  font-family: 'Cabinet Grotesk', sans-serif;
  font-weight: 500;
  color: black;
  text-decoration: none;

  @media (max-width: 768px) {
    font-size: 0.9rem;
    width: 90%;
  }
`;

const NavigationComponent = (): React.JSX.Element => {
  const [prevScrollPos, setPrevScrollPos] = React.useState(0);
  const [visible, setVisible] = React.useState(true);

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;

    setVisible(prevScrollPos > currentScrollPos);

    setPrevScrollPos(currentScrollPos);
  };

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos, visible]);

  return (
    <NavbarStyled fixed='top' className={visible ? 'active' : 'hidden'}>
      <Container>
        <HashLink to='./#home'>
          <Logo
            src={LogoImage}
            className='d-inline-block align-top'
            alt='150K logo'
          />
        </HashLink>
        <Navbar.Toggle aria-controls='responsive-navbar-nav' />
        <Navbar.Collapse id='responsive-navbar-nav'>
          <NavStyled className='me-auto'>
          </NavStyled>
          <NavStyled className=' align-items-center justify-content-end'>
            <NavLink className='px-2' to='./#home'>Home</NavLink>
            <NavLink className='px-2' to='./#product'>Produk</NavLink>
            {/*<NavLink className='px-2' to='./#partnership'>Kemitraan</NavLink>*/}
            <NavLink className='px-2' to='./#store'>Gerai</NavLink>
          </NavStyled>
        </Navbar.Collapse>
      </Container>
    </NavbarStyled>
  );
};

export default NavigationComponent;
