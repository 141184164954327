import React from 'react';
import styled from 'styled-components';

const Caption = styled.h3`
  font-family: 'Cabinet Grotesk', sans-serif;
  font-size: 50px;
  padding-left: 7vw;
  padding-top: 25vh;
`;

const SecondCaption = styled.p`
  font-family: 'Cabinet Grotesk', sans-serif;
  padding-top: 8vh;
  padding-left: 7vw;
  font-weight: bold;
`;

const Eye = styled.span`
  font-family: 'Freestyle Script', sans-serif;
  font-size: 8rem;
`;

const BannerComponent = (): React.JSX.Element => {
  return (
    <div className="mt-5">
      <div className='banner' id='home'>
        <Caption>berawal dari <Eye>Mata</Eye></Caption>
        <SecondCaption>150K OPTIK sejak 2014</SecondCaption>
      </div>
    </div>
  );
};

export default BannerComponent;
