import React from 'react';
import styled from 'styled-components';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { ContactComponent, NavigationComponent } from './Components';
import { LandingPageScreen, PartnerFormScreen } from './Screens';

export const DefaultContainer = styled.div`
  padding: 5vw 8vh !important;

  @media (max-width: 768px) {
    padding: 7vw 9vw !important;
  }
`;

const App = (): React.JSX.Element => {
  return (
    <Router>
      <NavigationComponent />
      <ToastContainer />
      <Routes>
        <Route path='/' element={<LandingPageScreen />} />
        {/*<Route path='form' element={<PartnerFormScreen />} />*/}
      </Routes>
      <ContactComponent />
    </Router>
  );
};

export default App;
