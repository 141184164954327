import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Title = styled.h3`
  font-family: 'Cabinet Grotesk', sans-serif;
  font-weight: bold;
  font-size: 2rem;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const SecondTitle = styled.h1`
  font-family: 'Cabinet Grotesk', sans-serif;
  font-weight: bolder;
  font-size: 7rem;
  padding-top: 3vh;

  @media (max-width: 768px) {
    font-size: 3.5rem;
  }
`;

const ThirdTitle = styled.h4`
  padding-top: 3vh;
  padding-bottom: 3vh;
  font-size: 1.7rem;
  font-family: 'Cabinet Grotesk', sans-serif;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const Button = styled.button`
  font-family: 'Cabinet Grotesk', sans-serif;
  border: none;
  background-color: white;
  border-radius: 8px;
  padding: 1vh 2vw;
  font-size: 1.7rem;
  font-weight: bold;
  color: black;
  
  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const Container = styled.div`
  padding: 10vh 8vw;
`;

const PartnershipComponent = (): React.JSX.Element => {
  return (
    <div className='partnership' id='partnership'>
      <Container>
        <Title>150K OPTIK + YOU</Title>
        <SecondTitle>Kemitraan <br /> dengan 150K</SecondTitle>
        <ThirdTitle>Kerjasama dengan kami untuk membuka <br /> 150K Optik berikutnya di wilayah kamu</ThirdTitle>
        <Link to='form#mitra'>
          <Button>Pelajari Lebih</Button>
        </Link>
      </Container>
    </div>
  );
};

export default PartnershipComponent;
