import React from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import axios from 'axios';
import { toast } from 'react-toastify';
import { FaHandshake } from 'react-icons/fa';
import { Col, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import CryptoJS from 'crypto-js';

import { DefaultContainer } from '../../App';

interface FormData {
  name: string;
  id: string;
  gender: string;
  email: string;
  phoneNumber: string;
  address: string;
}

const Title = styled.h1`
  font-family: 'Cabinet Grotesk', sans-serif;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const Caption = styled.h3`
  font-family: 'Cabinet Grotesk', sans-serif;
  font-weight: bold;
  color: grey;
`;

const Input = styled.input`
  font-family: 'Cabinet Grotesk', sans-serif;
  font-weight: bold;
  font-size: 1.5rem;
  width: 100%;
  border-top: none;
  border-right: none;
  border-left: none;
  border-image: initial;
  border-bottom: 1px solid rgb(34, 35, 37);
  border-radius: 0;
  margin-top: 1vh;
  margin-right: 0;

  &:focus {
    outline: none;
  }
`;

const StyledSelect = styled.select`
  font-family: 'Cabinet Grotesk', sans-serif;
  font-weight: bold;
  font-size: 1.5rem;
  width: 100%;
  border-top: none;
  border-right: none;
  border-left: none;
  border-image: initial;
  border-bottom: 1px solid rgb(34, 35, 37);
  border-radius: 0;
  margin-top: 1vh;
  margin-right: 0;
  color: black;

  &:focus {
    outline: none;
  }
`;

const StyledOption = styled.option`
  font-family: 'Cabinet Grotesk', sans-serif;
  font-weight: bold;
  font-size: 1.5rem;
  color: black;
`;

const Button = styled.button`
  font-family: 'Cabinet Grotesk', sans-serif;
  border: none;
  background-color: ${props => (props.disabled ? '#f0f0f0' : '#1e1e1e')};
  color: ${props => (props.disabled ? '#a0a0a0' : 'white')};
  border-radius: 8px;
  padding: 0.75vh 1.5vw;
  font-size: 1.4rem;
  font-weight: bold;
  margin-top: 2vh;

  @media (max-width: 768px) {
    font-size: 1.2rem;
    margin-top: 2vh;
    padding: 1vh 3vw;
  }
`;

const RequiredLabel = styled.p`
  font-family: 'Cabinet Grotesk', sans-serif;
  color: darkred;
  font-style: italic;
`;

const ShakeAnimation = (): React.JSX.Element => {
  return (
    <div className='icon-container'>
      <FaHandshake className='shake' fill='grey' size='300' />
    </div>
  );
};

const PartnerFormScreen = (): React.JSX.Element => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { register, handleSubmit, formState: { errors }, reset } = useForm<FormData>();

  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const getAuthWhatsappAPI = async (data: FormData) => {
    try {
      const headers = {
        'Content-Type': 'application/json'
      };

      const message = `Hi, ${data.name} telah melakukan pengisian form pengajuan mitra dengan detail <br/>Email: ${data.email} <br/>No KTP: ${data.id} <br/>Nomor telepon: ${data.phoneNumber} <br/>Gender: ${data.gender} <br/>Alamat: ${data.address}`;

      const smtpConfig = {
        host: 'smtp.hostinger.com',
        port: 465,
        secure: false,
        username: 'no-reply@optik150k.com',
        password: 'YabisaLahMasaEngga1-'
      };

      const encryptedCredentials = CryptoJS.AES.encrypt(
        JSON.stringify(smtpConfig),
        'mindYourBusiness'
      ).toString();

      const payload = {
        targetEmail: 'partnership@optik150k.com',
        message,
        subject: 'Partnership Registration',
        encryptedCredentials
      };

      await axios.post('https://whatsapp-api.rickyjulpiter.com/email/send', payload, { headers });

      reset();
      toast.success('Terima kasih telah melakukan pengajuan mitra. Kami akan segera menghubungi anda (3-7 Hari Kerja)');
    } catch (error) {
      console.error(error);
      toast.error('Sorry, something went wrong. Please try again');
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = async (data: FormData) => {
    setIsLoading(true);
    await getAuthWhatsappAPI(data);
  };

  return (
    <DefaultContainer className='mt-5' id='mitra'>
      <hr />
      <Title className='mt-5'>Pengajuan Kemitraan</Title>
      <Caption className='mb-5'>Isi data diri kamu untuk kami hubungi</Caption>

      <Row>
        <Col md={7}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <Input type='text' placeholder='Nama Lengkap*' {...register('name', { required: true })} />
              {errors.name && <RequiredLabel>Name is required</RequiredLabel>}
            </div>
            <div className='mt-3'>
              <Input
                type='number'
                placeholder='No. KTP*'
                {...register('id', {
                  required: 'No. KTP is required',
                  minLength: {
                    value: 16,
                    message: 'No. KTP must be exactly 16 characters long'
                  },
                  maxLength: {
                    value: 16,
                    message: 'No. KTP must be exactly 16 characters long'
                  }
                })}
              />
              {errors.id && <RequiredLabel>{errors.id?.message}</RequiredLabel>}
            </div>
            <div className='mt-3'>
              <Input
                type='number'
                placeholder='Nomor Telepon Aktif*'
                {...register('phoneNumber', {
                  required: 'Phone number is required',
                  minLength: {
                    value: 8,
                    message: 'Phone number must be at least 8 characters long'
                  },
                  maxLength: {
                    value: 13,
                    message: 'Phone number must be less than 13 characters long'
                  }
                })}
              />
              {errors.phoneNumber && <RequiredLabel>{errors.phoneNumber?.message}</RequiredLabel>}
            </div>
            <div className='mt-3'>
              <Input type='email' placeholder='Email*' {...register('email', { required: true })} />
              {errors.email && <RequiredLabel>Email is required</RequiredLabel>}
            </div>
            <div className='mt-3'>
              <StyledSelect {...register('gender', { required: true })}>
                <StyledOption value='male'>Male</StyledOption>
                <StyledOption value='female'>Female</StyledOption>
              </StyledSelect>
              {errors.gender && <RequiredLabel>Gender is required</RequiredLabel>}
            </div>
            <div className='mt-3'>
              <Input type='text' placeholder='Alamat Kantor' {...register('address', { required: false })} />
            </div>

            <Button type='submit' disabled={isLoading}>{isLoading ? 'Sedang diproses ...' : 'Kirim Pengajuan'}</Button>
          </form>
        </Col>
        <Col>
          <ShakeAnimation />
        </Col>
      </Row>
    </DefaultContainer>
  );
};

export default PartnerFormScreen;
