import React from 'react';
import styled from 'styled-components';
import { Carousel } from 'react-bootstrap';

import Optic1Image from '../../Assets/Images/optik_1.png';
import Optic2Image from '../../Assets/Images/optik_2.png';
import Optic3Image from '../../Assets/Images/optik_3.png';
import Optic4Image from '../../Assets/Images/store3A.png';
import Optic5Image from '../../Assets/Images/optik_5.png';
import { DefaultContainer } from '../../App';

const Title = styled.h1`
  font-family: 'Cabinet Grotesk', sans-serif;
  font-weight: bolder;
`;

const StoreMainTitle = styled(Title)`
  font-size: 3.5rem;

  @media (max-width: 768px) {
    font-size: 1.3rem;
  }
`;

const SecondTitle = styled.h3`
  font-family: 'Cabinet Grotesk', sans-serif;
  font-weight: bolder;
`;

const Caption = styled.h5`
  font-family: 'Cabinet Grotesk', sans-serif;
  margin-top: 3vh;
`;

const StoreTitle = styled.h5`
  font-family: 'Cabinet Grotesk', sans-serif;
  font-weight: bolder;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const Button = styled.button`
  font-family: 'Cabinet Grotesk', sans-serif;
  border: none;
  background-color: white;
  border-radius: 8px;
  padding: 0.75vh 1.5vw;
  font-size: 1.7rem;
  font-weight: bold;
  margin-top: 2vh;
  color: black;

  @media (max-width: 768px) {
    font-size: 1rem;
    margin-top: 1vh;
    padding: 0.2vh 2vw;
  }
`;

const CarouselImage = styled.img`
  width: 100%;
  height: 100%;

  @media (max-width: 768px) {
    width: 200% !important;
    height: 200% !important;
  }
`;

const ImageSlider = () => {
  const slides = [
    {
      title: '150K Optik',
      description: 'Store#01',
      location: 'https://goo.gl/maps/kR6N18aRzCDqYY4j7',
      imageUrl: Optic2Image
    },
    {
      title: '150K Optik',
      description: 'Store#02',
      location: 'https://goo.gl/maps/cxaxnrbeuWPJWt5i8',
      imageUrl: Optic1Image
    },
    {
      title: '150K Optik',
      description: 'Store#03',
      location: 'https://goo.gl/maps/FQtDnvWJxY3v2H536',
      imageUrl: Optic3Image
    },
    {
      title: '150K Optik',
      description: 'Store#04',
      location: 'https://maps.app.goo.gl/GP7eLjFCTcVQyW3w7',
      imageUrl: Optic4Image
    },
    {
      title: '150K Optik',
      description: 'Store#05',
      location: 'https://maps.app.goo.gl/arrLVccoGn1siWrA9',
      imageUrl: Optic5Image
    }
  ];


  return (
    <Carousel className='mt-5 rounded' fade={true}>
      {slides.map((slide, index) => (
        <Carousel.Item key={index}>
          <div className='slider-content'>
            <CarouselImage
              className='d-block'
              src={slide.imageUrl}
              alt={`Slide ${index + 1}`}
            />
            <div className='slider-caption'>
              <StoreTitle className='mb-lg-5'>{slide.title}</StoreTitle>
              <StoreMainTitle>{slide.description}</StoreMainTitle>
              <a href={slide.location} target='_blank'>
                <Button>Lokasi</Button>
              </a>
            </div>
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

const StoreComponent = (): React.JSX.Element => {
  return (
    <React.Fragment>
      <div className='pt-5 px-5' id='store'>
        <center>
          <Title>
            Gerai Kami
          </Title>
        </center>
      </div>
      <ImageSlider />

      <DefaultContainer>
        <SecondTitle>
          SATU HARGA UNTUK SEMUA
        </SecondTitle>
        <Caption>
          Satu harga untuk semua kacamata dengan kualitas premium. Dengan 170K dapatkan frame + lensa untuk semua jenis
          frame premium. Kami memiliki beragam pilihan kacamata untuk pria dan wanita, dari Cat Eyes, bulat sampai model
          Aviator.
        </Caption>
      </DefaultContainer>
    </React.Fragment>
  );
};

export default StoreComponent;
