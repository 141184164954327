import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Product01 from '../../Assets/Images/product01.jpg';
import Product02 from '../../Assets/Images/product02.jpg';
import Product03 from '../../Assets/Images/product03.jpg';
import Product04 from '../../Assets/Images/product04.jpg';
import Product05 from '../../Assets/Images/product05.jpg';
import Product06 from '../../Assets/Images/product06.jpg';
import Product07 from '../../Assets/Images/product07.jpg';
import Product08 from '../../Assets/Images/product08.jpg';

const Title = styled.h1`
  font-family: 'Cabinet Grotesk', sans-serif;
  font-weight: 800;
`;

const ContainerStyled = styled.div`
  background-color: #E7E3DF;
`;

const SlideContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #f2f2f2;
  width: 100%;
`;

const SlideImage = styled.img`
  max-width: 100%;
  object-fit: contain !important;
`;

const settings = {
  dots: false,
  infinite: true,
  speed: 300,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};

const images = [
  {
    src: Product01
  },
  {
    src: Product02
  },
  {
    src: Product03
  },
  {
    src: Product04
  },
  {
    src: Product05
  },
  {
    src: Product06
  },
  {
    src: Product07
  },
  {
    src: Product08
  }
];

const ProductComponent = (): React.JSX.Element => {
  return (
    <React.Fragment>
      <ContainerStyled className='py-5' id="product">
        <center>
          <Title>Produk</Title>
        </center>
      </ContainerStyled>

      <Slider {...settings} className="mb-5">
        {
          images?.map((item, index) => (
            <SlideContainer key={index}>
              <SlideImage src={item.src} alt={`image ${index}`} className="img-fluid" />
            </SlideContainer>
          ))
        }
      </Slider>
    </React.Fragment>
  );
};

export default ProductComponent;
